import React, { useEffect, useState } from "react"
import { graphql } from "gatsby";
import { Layout, SEO, CJumbotron, CBreadCrumb } from "../../components/_index"
import PrivateLayout from "../../components/_extra/private_layout"

// markup
const SubPage = ({ data, pageContext }: any) => {
  const news = data.microcmsPrivateSendai
  return (
    <Layout>
      <SEO title={news.title}
        meta={[
          {
            name: `robots`,
            content: `noindex`,
          },]}
      />
      <PrivateLayout data={news} />
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($id: String!) {
    microcmsPrivateSendai(id:{eq:$id}) {
      privateSendaiId
      publishedAt
      title
      classification
      basicauth
      contents {
        fieldId
        heading_text
        wysiwyg
        box
        caption
        label
        table
        youtube
        htmlcode
        type
        align
        image {
          url
          width
          height
        }
        images {
          caption
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
        }
        keyvalue {
          fieldId
          keyname
          value
          value2
        }
        list{
          value
        }
        link {
          href
          blank
        }
        pdf {
          url
        }
        margin {
          margin
        }
        accordion {
          title
          contents {
            fieldId
            heading_text
            wysiwyg
            box
            caption
            label
            table
            youtube
            htmlcode
            type
            align
            image {
              url
              width
              height
            }
            link {
              href
              blank
            }
            pdf {
              url
            }
            margin {
              margin
            }
          }
        }
        left {
          fieldId
          heading_text
          wysiwyg
          box
          caption
          label
          table
          youtube
          htmlcode
          type
          align
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
        right {
          fieldId
          heading_text
          wysiwyg
          box
          caption
          label
          table
          youtube
          htmlcode
          type
          align
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
      }
    }
  }
`
